<template>
<table :class="`w100 table__border--${table_style}`">
    <thead :class="`table__head--${table_style}`">
        <th class="table__head--text">Vlag</th>
        <th class="table__head--text">Land</th>
        <th class="table__head--text">Totaal</th>
        <th v-if="table_style == 200" class="table__head--text">trekkers &lt;= 3 jaar</th>
        <th v-if="table_style == 200" class="table__head--text">trekkers > 3jaar</th>
        <th v-if="table_style == 200" class="table__head--text">Bakwagens &lt;= 3 jaar</th>
        <th v-if="table_style == 200" class="table__head--text">Bakwagens > 3 jaar	</th>
        <th v-if="table_style == 200" class="table__head--text">Opleggers</th>
        <th v-if="table_style == 200" class="table__head--text">Aanhangwagens</th>
        <th v-if="table_style == 200" class="table__head--text">Overig</th>
    </thead>
    <tbody>
        <tr v-for="(item, key) in table_data" :key="key" :class="`table__row--color`">
            <td class="table__cell--default"><Flag v-if="item.land != 'GB'" :code="item.land" /> <Flag v-else :code="`GB-UKM`" /></td>
            <td class="table__cell--default">{{ item.landnaam }}</td>
            <td class="table__cell--default">{{ item.aantal }}</td>
            <td v-if="table_style == 200" class="table__cell--default"><span v-if="item.trekkers_3 > 0">{{ item.trekkers_3 }}</span></td>
            <td v-if="table_style == 200" class="table__cell--default"><span v-if="item.trekkers > 0">{{ item.trekkers }}</span></td>
            <td v-if="table_style == 200" class="table__cell--default"><span v-if="item.trekkers_3 > 0">{{ item.trekkers_3 }}</span></td>
            <td v-if="table_style == 200" class="table__cell--default"><span v-if="item.bakwagens > 0">{{ item.bakwagens }}</span></td>
            <td v-if="table_style == 200" class="table__cell--default"><span v-if="item.opleggers > 0">{{ item.opleggers }}</span></td>
            <td v-if="table_style == 200" class="table__cell--default"><span v-if="item.aanhangwagens > 0">{{ item.aanhangwagens }}</span></td>
            <td v-if="table_style == 200" class="table__cell--default"><span v-if="item.overig > 0">{{ item.overig }}</span></td>
        </tr>
    </tbody>
</table>
</template>
<script>
export default {
    props:{
        table_data: [Object, Array],
        table_style: [String, Number],
    }
}

</script>