<template>
  <div :class="`m-3 p-2 wrapper wrapper--${bu}`">
    <div class="text-center">
      <h2 class="text-center">Inkopen per land</h2>
      <span @click="previousYear"><i class="fas fa-arrow-left"></i></span>
      <span class="text-center m2"
        >Jaar: {{ year_number }}</span
      >
      <span @click="nextYear"><i class="fas fa-arrow-right"></i></span>
    </div>
    <hr>
    <Loading v-if="loading" />
    <div class="flexbox _row" v-else>
      <div class="flexgrow center">
        <h3 class="flexgrow text-center">Eigen voorraad</h3>
        <BoughtPerCountryTable :table_data="own_vehicles" :table_style="bu" />
      </div>
      <div class="flexgrow center m2">
        <h3 class="text-center">Consignatie</h3>
        <BoughtPerCountryTable :table_data="consignment" :table_style="bu" />
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/functions/request.js";
import Loading from "@/components/Loading.vue";
import { subtractTime, addTime } from "@/functions/date.js";
import BoughtPerCountryTable from "@/components/BoughtPerCountryTable.vue"

export default {
  props: ["bu"],
  components: { Loading, BoughtPerCountryTable },
  data: () => ({
    full_date: null,
    year_number: null,
    own_vehicles: null,
    consignment: null,
    loading: null,
  }),
  created() {
    this.full_date = this.moment();
    this.getData(this.bu);
  },
  watch:{
    bu(newbu){
      this.getData(newbu);
    }
  },
  methods: {
    getData(bu) {
      this.loading = true;
      this.year_number = this.full_date.year();
      request(`bought-per-country/${this.year_number}/${bu}`, "GET").then(
        ({ own_vehicles, consignment }) => {
          this.own_vehicles = own_vehicles;
          this.consignment = consignment;
          this.loading = false;
        }
      );
    },

    previousYear() {
      this.full_date = subtractTime(1, this.full_date, "years");
      this.getData(this.bu);
    },

    nextYear() {
      this.full_date = addTime(1, this.full_date, "years");
      this.getData(this.bu);
    },
  },
};
</script>
